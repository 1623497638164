import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { RootState } from 'app/store';
import { LanguageData } from 'interfaces/campaignInterfaces';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import {
    sendRewardData,
    setRewardsLanguageData,
    removeRewardsLanguage,
    setRewardsLanguageCurrentLanguage
} from '../../../../state/campaign/campaignSlice';
import { ReactComponent as DeleteIcon } from 'static/icons/delete.svg';
import { ReactComponent as CheckIcon } from 'static/icons/checkmark.svg';
import styles from './RewardLanguageForm.module.scss';

interface languageItem {
    label: string,
    value: string
}

interface Props {
    language: string;
    addedLanguagesOptions: languageItem[];
}

export default function RewardLanguageForm({ language, addedLanguagesOptions }: Props): React.ReactElement {
    const dispatch = useDispatch();

    const languageData = useSelector<RootState, LanguageData>(
        (state) => state.campaign.rewardsLanguages.values[language]
    );
    const campaignEmailTemplate = useSelector<RootState, any>(
        (state) => state?.campaign?.campaign?.properties?.template?.email
    );
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);
    const [preservedData, setPreservedData] = useState(languageData);
    const [validLink, setValidLink] = useState(false);

    useEffect(() => {
        setPreservedData(languageData)
    }, [languageData])

    useEffect(() => {
        if (preservedData?.reward_link?.length > 0) {
            handleValidate(preservedData?.reward_link)
        } else {
            setValidLink(false)
        }
    }, [preservedData?.reward_link?.length])

    const dispatchValueChange = (name: string, value: string) => {
        dispatch(
            setRewardsLanguageData({
                language,
                field: name,
                value,
            })
        );
    };

    const saveLanguageList = () => {
        dispatch(sendRewardData())
    }

    const removeLanguageList = () => {
        const filteredLanguages = addedLanguagesOptions.filter(lang => lang.value !== language)
        dispatch(setRewardsLanguageCurrentLanguage(filteredLanguages[0].value));
        dispatch(removeRewardsLanguage(language));
        dispatch(sendRewardData());
    }

    const preserveLanguageData = (key: string, value: string) => {
        const newData = cloneDeep(preservedData);
        newData[key] = value;
        setPreservedData(newData);
    }

    const handleChange = (name: string, value: string) => {
        preserveLanguageData(name, value);
        dispatchValueChange(name, value);
    }

    const handleValidate = (value: string) => {
        const pattern = new RegExp(
            '^(https:\\/\\/)' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+{}]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-{}]*)?' + // query string
            '(\\#[-a-z\\d_{}]*)?$', // fragment locator
            'i'
        );
        const check = !pattern.test(value);
        setValidLink(check)
    }
    
    return (
        <form className={styles.root}
            onSubmit={(e) => {
                e.preventDefault();
                saveLanguageList();
            }}>

            <div className={styles.imageBlock}>
                <ImageUpload
                    verticalLayout={true}
                    showPreview={true}
                    imageUrl={languageData?.reward_image_url}
                    resize={campaignEmailTemplate?.reward_image_url?.resize}
                    ImageSetupTemplate={campaignEmailTemplate?.reward_image_url}
                    applyOnUrlChange={true}
                    error={!preservedData.reward_image_url}
                    onImageSubmit={(e) => handleChange('reward_image_url', e)}
                />
                <ImageUpload
                    verticalLayout={true}
                    showPreview={true}
                    imageFieldLabel='Button Image URL'
                    imageUrl={languageData?.reward_link_image}
                    resize={campaignEmailTemplate?.reward_link_image.resize}
                    ImageSetupTemplate={campaignEmailTemplate?.reward_link_image}
                    applyOnUrlChange={true}
                    error={!!preservedData.reward_link && !preservedData.reward_link_image}
                    onImageSubmit={(e) => handleChange('reward_link_image', e)}
                />
            </div>

            <div className={styles.fieldsBlock}>
                <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>
                        Title <span className={styles.red}>*</span>
                    </label>
                    <Input
                        required
                        className={styles.formInput}
                        value={preservedData.reward_title}
                        name="reward_title"
                        disabled={isCampaignLoading}
                        onChange={(e) => handleChange('reward_title', e.target.value)}
                    />
                </div>

                <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>
                        Message <span className={styles.red}>*</span>
                    </label>
                    <Input
                        required
                        className={styles.formInput}
                        value={preservedData.reward_message}
                        name="reward_message"
                        disabled={isCampaignLoading}
                        onChange={(e) => handleChange('reward_message', e.target.value)}
                    />
                </div>

                <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>
                        Description <span className={styles.red}>*</span>
                    </label>
                    <Input
                        required
                        multiline={true}
                        className={styles.textarea}
                        value={preservedData.reward_description}
                        name="reward_description"
                        disabled={isCampaignLoading}
                        onChange={(e) => handleChange('reward_description', e.target.value)}
                    />
                </div>

                <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Terms</label>
                    <Input
                        multiline={true}
                        className={styles.textarea}
                        value={preservedData.reward_terms}
                        name="reward_terms"
                        disabled={isCampaignLoading}
                        onChange={(e) => handleChange('reward_terms', e.target.value)}
                    />
                </div>

                <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>URL Link</label>
                    <Input
                        className={styles.formInput}
                        value={preservedData.reward_link}
                        name="reward_link"
                        disabled={isCampaignLoading}
                        onChange={(e) => handleChange('reward_link', e.target.value)}
                    />
                    {validLink && <div className={styles.red}>Please use a valid URL</div>}
                </div>

                <div className={styles.inlineActions}>
                    <Button size="large"
                        type="action"
                        submit
                        disabled={isCampaignLoading || validLink}
                        iconLeft={<CheckIcon height={24} />}
                        className={styles.submitButton}>
                        Apply changes
                    </Button>
                    {addedLanguagesOptions.length > 1 && (
                        <Button size="large"
                            type="danger"
                            disabled={isCampaignLoading}
                            className={styles.submitButton}
                            iconLeft={<DeleteIcon height={24} />}
                            onClick={() => removeLanguageList()}>
                            Remove Language
                        </Button>
                    )}
                </div>
            </div>
        </form>
    );
}
